import React from "react";
import Zoom from "react-reveal/Zoom";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Layout from "../../components/Layout";
import ContactForm from "../../components/ContactForm";
import useSiteMetadata from "../../components/SiteMetadata";
import { useMisc } from "../../hooks/useMisc";

const seo = {
  title: "Contact Us if you want to buy cordyceps in bulk.",
  description:
    "Buy cordyceps in bulk - Top quality Cordyceps and guaranteed purity. Buy any amount and get super fast delivery anywhere in India."
};

const ContactPage = () => {
  const { siteUrl } = useSiteMetadata();
  const {
    footer: { phone, email, address }
  } = useMisc();
  const { streetName, locality, city, state, postalCode, country } = address;
  const CONTACT_ITEMS = [
    {
      icon: require("../../../static/images/phone.svg"),
      title: "phone no.",
      description: phone,
      href: `tel:${phone}`
    },
    {
      icon: require("../../../static/images/marker.svg"),
      title: "address",
      description: `${streetName}, ${locality}, ${city} (${state}) ${postalCode} ${country}`,
      href:
        "https://www.google.com/maps/place/Mycoforest/@26.2283347,78.1578567,17z/data=!3m1!4b1!4m12!1m6!3m5!1s0x3976c68962d774eb:0x80166921f79d1e72!2sMycoforest!8m2!3d26.2283347!4d78.1600454!3m4!1s0x3976c68962d774eb:0x80166921f79d1e72!8m2!3d26.2283347!4d78.1600454" // map URL
    },
    {
      icon: require("../../../static/images/envelope.svg"),
      title: "mail id",
      description: email,
      href: `mailto:${email}`
    }
  ];

  return (
    <Layout isContactPage>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        openGraph={{
          url: `${siteUrl}/contact`,
          title: seo.title,
          description: seo.description,
          site_name: "MycoForest"
        }}
      />
      <ContactForm />
      <section>
        <div className="container">
          <h2>
            Contact Us and know more about how our Cordyceps experts can help
            you with your requirements
          </h2>
          <div className="items">
            {CONTACT_ITEMS.map(item => (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={item.href}
                key={item.title}
              >
                <Zoom>
                  <div className="image-container">
                    <img src={item.icon} alt={item.title} />
                  </div>
                </Zoom>
                <div className="content">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
        <style jsx>{`
          section {
            background: #f5f0eb;
            padding: 32px;
            padding-bottom: 100px;
          }
          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          h2 {
            text-align: center;
            font-family: "Open Sans", sans-serif;
            font-size: 24px;
          }

          .image-container {
            width: 160px;
            height: 160px;
            margin: 0 auto;
            margin-bottom: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }

          .items {
            display: flex;
            max-width: 1080px;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 auto;
            margin-top: 80px;
          }

          .items a {
            display: block;
            width: calc(33.33% - 32px);
            margin: 0 auto;
            text-align: center;
            margin: 16px;
            color: inherit;
            font-family: "Lato", sans-serif;
          }

          h3 {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
          }
          p {
            font-weight: 300;
            font-size: 18px;
            max-width: 200px;
            margin: 0 auto;
            line-height: 1.5;
          }

          @media (max-width: 900px) {
            h2 {
              font-size: 20px;
            }

            .items {
              flex-direction: column;
              margin-top: 48px;
              justify-content: unset;
            }
            .items a {
              width: 100%;
              display: flex;
              align-items: center;
            }
            .image-container {
              width: 50px;
              height: 50px;
              margin: 0;
              margin-right: 16px;
            }

            .image-container img {
              width: 32px;
              height: 32px;
              object-fit: contain;
            }

            .content {
              flex: 1;
            }

            h3,
            p {
              width: auto;
              max-width: unset;
              text-align: left;
              margin: 0;
            }

            h3 {
              font-size: 16px;
              margin-bottom: 2px;
            }
          }

          @media (max-width: 480px) {
            section {
              padding: 64px 20px;
              padding-top: 32px;
            }
          }
        `}</style>
      </section>
    </Layout>
  );
};

export default ContactPage;
