import React, { Component } from "react";
import { navigate } from "gatsby-link";

import Button from "./Button";
import deskImage from "../../static/images/desk.svg";
import { encode } from "../utils";

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, isSubmitting: false };
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    this.setState({ isSubmitting: true });
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => {
        this.setState({ isSubmitting: false });
        navigate(form.getAttribute("action"));
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
        alert(error);
      });
  };
  render() {
    return (
      <section>
        <div className="container">
          <div className="image">
            <img src={deskImage} alt="desk" />
          </div>
          <div className="content">
            <h1>
              Talk to our experts about <br /> your requirements
            </h1>
            <form
              name="contact"
              method="post"
              action="/contact/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <div className="fields">
                <div className="field">
                  <label className="label" htmlFor={"name"}>
                    Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                      disabled={this.state.isSubmitting}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"phone"}>
                    Phone
                  </label>
                  <div className="control">
                    <div className="prefix">+91</div>
                    <input
                      className="input"
                      type={"tel"}
                      name={"phone"}
                      onChange={this.handleChange}
                      id={"phone"}
                      required={true}
                      disabled={this.state.isSubmitting}
                    />
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={"email"}>
                  Email
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"email"}
                    name={"email"}
                    onChange={this.handleChange}
                    id={"email"}
                    required={true}
                    disabled={this.state.isSubmitting}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={"message"}>
                  Message
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={"message"}
                    onChange={this.handleChange}
                    id={"message"}
                    required={true}
                    cols="30"
                    rows="4"
                    disabled={this.state.isSubmitting}
                  />
                </div>
              </div>
              <div className="submit-container">
                <Button
                  width={180}
                  type="submit"
                  isSubmitting={this.state.isSubmitting}
                  disabled={this.state.isSubmitting}
                >
                  Send
                </Button>
              </div>
            </form>
          </div>
        </div>
        <style jsx>{`
          section {
            background: #f5f0eb;
            padding: 32px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .image {
            width: 100%;
            max-width: 600px;
            margin-right: 16px;
          }

          .image img {
            width: 100%;
          }

          .content {
            width: 100%;
            max-width: 600px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-family: "Open Sans", sans-serif;
            font-weight: 300;
          }

          h1 {
            text-align: right;
            font-size: 48px;
            font-weight: 400;
            margin-top: 0;
          }

          form {
            width: 100%;
          }

          .fields {
            display: flex;
          }

          .fields .field:first-child {
            margin-right: 28px;
          }

          .form .field:last-child {
            margin-bottom: 0;
          }

          .field {
            width: 100%;
            margin-bottom: 16px;
          }
          label {
            color: #444;
            font-size: 16px;
            margin-bottom: 4px;
            display: inline-block;
          }

          .control {
            display: flex;
            position: relative;
          }

          .control .prefix {
            background: #fff;
            display: flex;
            align-items: center;
            color: #000;
            border-radius: 7px;
            border-right: 1px solid #eee;
            width: 50px;
            justify-content: center;
            font-size: 14px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          .control .prefix + input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          input,
          textarea {
            width: 100%;
            padding: 14px 10px;
            border-radius: 7px;
            display: block;
            border: none;
            outline: none;
            font-size: 16px;
          }

          .submit-container {
            margin-top: 36px;
            display: flex;
            justify-content: flex-end;
          }

          @media (max-width: 1180px) {
            .image,
            .content {
              max-width: 500px;
            }

            h1 {
              font-size: 36px;
            }
          }

          @media (max-width: 940px) {
            h1 {
              font-size: 32px;
            }
          }

          @media (max-width: 845px) {
            .container {
              flex-direction: column;
            }
            .image {
              margin-right: 0;
              margin-top: 32px;
              margin-bottom: 32px;
            }
            .content {
              max-width: 900px;
            }
            h1 {
              font-size: 32px;
              text-align: left;
              width: 100%;
            }
          }

          @media (max-width: 600px) {
            .fields {
              flex-direction: column;
            }

            .fields .field:first-child {
              margin-right: 0;
              margin-bottom: 16px;
            }

            .form .field:last-child {
              margin-bottom: 16px;
            }
          }

          @media (max-width: 480px) {
            section {
              padding: 32px 20px;
            }

            h1 {
              font-size: 24px;
            }
          }
        `}</style>
      </section>
    );
  }
}
